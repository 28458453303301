<template>
  <div>
    <div class="mt-4">
      <v-data-table
        :headers="headers"
        :items="blacklists"
        sort-by="descricao"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Blacklist registrados</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on"
                  >Novo Blacklist</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>

                    <v-row v-if="!empresaAtual.gate">
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="editedItem.gate"
                          label="Gate"
                          :items="selectGate"
                          :item-text="
                            (item) =>
                              item.business_name +
                              ' - ' +
                              `${item.cnpj != null ? item.cnpj : item.cpf}`
                          "
                          item-value="public_id"
                          @change="selectedGate()"
                          return-object
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="editedItem.fornecedor"
                          label="Fornecedor"
                          :items="selectFornecedor"
                          :item-text="
                            (item) =>
                              item.business_name +
                              ' - ' +
                              `${item.cnpj != null ? item.cnpj : item.cpf}`
                          "
                          item-value="public_id"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                         <v-autocomplete
                          label="Transportadora"
                          :items="transportadorasSelecionadas"
                          :search-input.sync="searchTransportadora"
                          v-model="editedItem.transportadora"
                          item-text="trade_name"
                          item-value="public_id"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title v-text="item.trade_name"></v-list-item-title>
                              <v-list-item-subtitle
                                v-text="`CNPJ ${item.cnpj} - ${item.city} - ${item.district}`"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          label="Veiculo"
                          :items="veiculosSelecionados"
                          :search-input.sync="searchVeiculo"
                          v-model="editedItem.veiculo"
                          item-text="placa_cavalo"
                          item-value="id"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          label="Motorista"
                          :items="motoristas"
                          :search-input.sync="getMotoristas"
                          v-model="editedItem.motorista"
                          :item-text="motoristaText"
                          item-value="public_id"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          class="mb-4"
                          v-model="editedItem.observacao"
                          label="Observação"
                          name="observacao"
                          hint="Motivo do blacklist"
                          persistent-hint
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                         <v-checkbox
                            v-model="editedItem.active"
                            label="Ativo"
                            class="ma-0 pa-0"
                          />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save">Salvar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="prepareDeleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getInfo">Reset</v-btn>
        </template>
      </v-data-table>

      <v-dialog v-model="dialogDeleteItem" max-width="500px">
        <v-card>
          <v-card-title>
            <v-card-text>
              <h3 class="transition-swing text-h3 mb--1 error--text">Cuidado!</h3>
              <p class="mt-8">Tem certeza que deseja deletar este registro?</p>
            </v-card-text>
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="
                dialogDeleteItem = false
                itemDelete = {}
              "
            >
              Cancelar
            </v-btn>
            <v-btn color="primary" text @click="deleteItem()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>

  
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ContratoAPI from '@/services/tenants/contratos'
import { cpf } from 'cpf-cnpj-validator'
import TransportadorasApi from '@/services/tenants/transportadoras.js'
import PatioApi from '@/services/patio/index'


export default {
  data() {
    return {
      dialog: false,
      selectFornecedor: [],
      transportadorasSelecionadas: [],
      motoristasSelecionados: [],
      motoristaVeiculo: [],
      selectGate: [],
      search: '',
      searchTransportadora: '',
      veiculosSelecionados: [],
      veiculoVeiculo: [],
      searchVeiculo: '',
      getMotoristas: '',
      veiculoText: '',
      transportadoraText: '',
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Gate', value: 'gate.business_name' },
        { text: 'Fornecedor', value: 'fornecedor.business_name' },
        { text: 'Transportadora', value: 'transportadora.business_name' },
        { text: 'Veiculo', value: 'veiculo.placa_cavalo' },
        { text: 'Motorista', value: 'motorista.user.name' },
        { text: 'Observação', value: 'observacao'},
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        gate: null,
        fornecedor: null,
        transportadora: null,
        veiculo: null,
        motorista: null,
        active: true,
        observacao: '',
        data: null,
      },
      defaultItem: {
        gate: null,
        fornecedor: null,
        transportadora: null,
        veiculo: null,
        motorista: null,
        active: true,
        observacao: '',
        data: null,
      },
      dialogDeleteItem: false,
      itemDelete: {},
    }
  },
  computed: {
    ...mapState('patio', ['blacklists']),
    ...mapState('auth', ['empresaAtual']),
    ...mapState('motoristas', ['motoristas']),
    formTitle() {
      return this.editedIndex === -1 ? 'Novo Blacklist' : 'Editar Blacklist'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    async searchTransportadora(searchTransportadora) {
      if (searchTransportadora !== null && searchTransportadora !== "") {
        if (searchTransportadora.length > 2 && searchTransportadora.length <= 5) {
          await this.getTransportadoraSearch({
            pesquisa: searchTransportadora,
          })
        } else if (searchTransportadora.length < 2) {
          this.transportadorasSelecionadas = []
        }
      }
    },

    async searchVeiculo(searchVeiculo) {
      if (searchVeiculo !== null && searchVeiculo !== "") {
        if (searchVeiculo.length > 2 && searchVeiculo.length <= 5) {
          await this.getVeiculoSearch(searchVeiculo)
        } else if (searchVeiculo.length < 2) {
          this.veiculosSelecionados = []
        }
      }
    },

    async getMotoristas(paramMotorista) {
      if (paramMotorista !== null && paramMotorista !== "") {
        if (paramMotorista.length > 2 && paramMotorista.length <= 12) {
          await this.getMotoristaSearch({
            pesquisa: paramMotorista,
          })
        } else if (paramMotorista.length < 2) {
          this.veiculosSelecionados = []
        }
      }
    },
  },

  async created() {
    this.getInfo()

    if (this.empresaAtual.gate) {
      this.selectGate = [this.empresaAtual]
      this.editedItem.gate = this.empresaAtual
      
      this.selectedGate()
    } else {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.selectGate = [
        { business_name: 'Nenhum', cnpj: '0', public_id: null },
        ...possible_gates,
      ]
    }
  },

  methods: {
    ...mapActions('patio', ['getBlacklists', 'addBlacklist', 'editBlacklist','deleteBlacklist']),
    ...mapActions('fornecedores', ['getFornecedores','getFornecedoresByParams']),
    ...mapActions('motoristas', ['getMotorista', 'excluirMotorista']),
    ...mapActions('transportadoras', [
      'getTransportadoras',
      'getTransportadoraEspecifica',
    ]),

    prepareDeleteItem(item) {
      this.dialogDeleteItem = true
      this.itemDelete = item
    },

    async getTransportadoraSearch(parametros) {
      if (!Object.keys(parametros).length) return

      try {
        const transportadora =
          await TransportadorasApi.getTransportadoraEspecifica(
            this.empresaAtual,
            parametros
          )

        this.transportadorasSelecionadas = transportadora
      } catch (e) {
        console.log(e)
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },

    async getVeiculoSearch(placa_cavalo){
      const veiculo = await PatioApi.getVeiculoParams({
        placa_cavalo: placa_cavalo,
      })
      this.veiculosSelecionados = veiculo
    },

    editItem(item) {
      this.editedIndex = this.Blacklists.indexOf(item)
      Object.assign(this.editedItem, item)
      this.dialog = true
    },

    async selectedGate() {
        await this.atualizarListaFornecedores()
    },

    async atualizarListaFornecedores() {
      try {

        if (!this.empresaAtual.gate) {
          const fornecedores = await this.getFornecedoresByParams({
            params: {
              fornecedor: this.empresaAtual.public_id,
            },
            empresa: this.editedItem.gate,
          })
          this.selectFornecedor = [
            { business_name: 'Nenhum', cnpj: '0', public_id: null },
            ...fornecedores,
          ]
        } else {
          const fornecedores = await this.getFornecedores()
          this.selectFornecedor = [
            { business_name: 'Nenhum', cnpj: '0', public_id: null },
            ...fornecedores,
          ]
        }

      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não é gate para ter fornecedores.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },

    async getMotoristaSearch(parametros) {
      try {

        await this.getMotorista({ search: parametros.pesquisa })

      } catch (e) {
        console.log(e)
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },

    motoristaText(item) {
      return `${item.user.name.toUpperCase()} - ${cpf.format(item.user.cpf.toUpperCase())}`
    },

    getInfo(){
      this.getBlacklists({
          proprietario: this.empresaAtual.public_id,
      })
    },

    async deleteItem() {
      await PatioApi.deleteBlacklist(this.itemDelete.id)
      this.dialogDeleteItem = false
      this.perifericoDelete = {}
      this.getInfo()
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      this.editedItem.data = new Date(Date.now())
      this.editedItem.gate = this.editedItem.gate.public_id
      this.addBlacklist(this.editedItem)
      this.dialog = false
      setTimeout(() => {
         this.getBlacklists({
          fornecedor: this.empresaAtual.public_id,
        })
      }, 300)
     
    },
  },
}
</script>
